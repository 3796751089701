import React, { useEffect, useState, useCallback } from 'react';
import { TextField, styled, Box, Paper, Typography, CircularProgress, InputAdornment, Input } from '@mui/material';
import {Wrapper,Info,InfoTitle,InfoWrapper} from './styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { calculatePercentageChange, recordsFilteredByPersonas, recordsFilteredByProfile } from '../utils';
import { Tooltip } from '@mui/material'
import { PERFORMANCE_OVERVIEW_TOOLTIPS } from '../../../constants/tooltips';
import moment from 'moment';
import { calculateMetricsByHourHelper, calculateMetricsHelper } from './utils';
import { selectBaselineSessionItems, selectSessionItems } from '../../../redux/selectors/chatSelectors';


const TextData = ( chatDataProp ) => {
  const personas = useSelector(state => state.content.personas);
  const isContentLoading = useSelector((state) => state.chat.loading);
  const records = useSelector(selectSessionItems, shallowEqual);
  const baselineRecords = useSelector(selectBaselineSessionItems, shallowEqual);
  const events = useSelector((state) =>  state.content.reactionRecordsV2) || [];
  const costPerTicket = sessionStorage.getItem("costPerTicket") ?? ( chatDataProp.costPerTicket ?? 6 );
  const eventCounts = useSelector(state => state.content.eventCounts);

  // Metrics state
  const [metrics, setMetrics] = useState({
    sessionsTotal: 0,
    totalQueries: 0,
    fcr: 0,
    costSavings: 0,
    csatScore: 0,
    engagementRate: 0,
    gaps: 0,
    trueQuestions: 0,
    queriesPerSession: 0,
    totalAutomations: 0,
    baseline: {
      sessionsTotal: 0,
      totalQueries: 0,
      fcr: 0,
      costSavings: 0,
      csatScore: 0,
      engagementRate: 0,
      gaps: 0,
      trueQuestions: 0,
      queriesPerSession: 0,
      totalAutomations: 0,
    },
  });
  
    // Function to calculate and set metrics
    const calculateAndSetMetrics = useCallback((records, events, baseline, costPerTicket) => {
      const calculateMetrics = (records, events) => {
        if(!records) return metrics;
        const diff = moment(chatDataProp.endDate).diff(chatDataProp.startDate, 'days');
        if(diff <= 1) return calculateMetricsByHourHelper(records, events, costPerTicket, eventCounts);
        else return calculateMetricsHelper(records, events, costPerTicket, eventCounts);
      };
      
      setMetrics({
        ...calculateMetrics(records, events),
        baseline: calculateMetrics(baseline, events),
      });
    }, [records, events, baselineRecords, eventCounts])
  
    useEffect(() => {
      const diff = moment(chatDataProp.endDate).diff(chatDataProp.startDate, 'days');
      let filteredRecords = recordsFilteredByPersonas(records, personas, chatDataProp.persona);
      filteredRecords = recordsFilteredByProfile(filteredRecords, chatDataProp.profile);
      let filteredEvents = recordsFilteredByPersonas(events, personas, chatDataProp.persona);
      filteredEvents = recordsFilteredByProfile(filteredEvents, chatDataProp.profile);

      calculateAndSetMetrics(filteredRecords, filteredEvents, baselineRecords, chatDataProp?.costPerTicket ?? 6, eventCounts);
    }, [records, baselineRecords, chatDataProp, chatDataProp?.costPerTicket, calculateAndSetMetrics, eventCounts]);

  const textDataRecords = {
    volumeMetrics: {
      total_sessions: {
        data: metrics.sessionsTotal,
        change: calculatePercentageChange(metrics.sessionsTotal, metrics.baseline.sessionsTotal).toFixed(2)
      },
      total_queries: {
        data: metrics.totalQueries,
        change: calculatePercentageChange(metrics.totalQueries, metrics.baseline.totalQueries).toFixed(2)
      },
    },
    performanceMetrics: {
      engagement_rate: {
        data: `${metrics.engagementRate}%`,
        change: calculatePercentageChange(metrics.engagementRate, metrics.baseline.engagementRate).toFixed(2),  
      },
      first_contact_resolution: {
        data: `${metrics.fcr}%`,
        change: calculatePercentageChange(metrics.fcr, metrics.baseline.fcr).toFixed(2),  
      },
      cost_savings: {
        data: `$${metrics.costSavings}`,
        change: calculatePercentageChange(metrics.costSavings, metrics.baseline.costSavings).toFixed(2),  
      },
      csat_score: {
        data: `${metrics.csatScore}/5`,
        change: calculatePercentageChange(metrics.csatScore, metrics.baseline.csatScore).toFixed(2),  
      },
      gaps: {
        data: `${metrics.gaps}`,
        change: calculatePercentageChange(metrics.gaps, metrics.baseline.gaps).toFixed(2),  
      },
      true_questions: {
        data: `${metrics.trueQuestions}%`,
        change: calculatePercentageChange(metrics.trueQuestions, metrics.baseline.trueQuestions).toFixed(2),  
      },
      queries_per_session: {
        data: `${metrics.queriesPerSession}`,
        change: calculatePercentageChange(metrics.queriesPerSession, metrics.baseline.queriesPerSession).toFixed(2)
      },
      total_automations: {
        data: `${metrics.totalAutomations}`,
        change: calculatePercentageChange(metrics.totalAutomations, metrics.baseline.totalAutomations).toFixed(2)
      }
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  // Return:
  return (
    <Wrapper>
  {isContentLoading ? (
    <CircularProgress sx = {{mt: '21rem'}}/>
  ) : (
    <Info>
      <InfoTitle>Performance Overview</InfoTitle>
      <InfoWrapper>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', mb: '3rem' }}>
          {Object.keys(textDataRecords?.volumeMetrics).map((data) => (
            <Box key={data} sx={{ width: 'calc(50% - 1rem)', marginBottom: '1rem', textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom style={{ textTransform: 'capitalize' }}>
                {data.replace(/_/g, ' ')}
              </Typography>
              <Typography variant="h2" style={{ fontSize: '4.5rem', fontWeight: 'bold' }}>
                {textDataRecords?.volumeMetrics[data]['data']}
              </Typography>
              <Typography variant="caption" display="block" style={{ color: textDataRecords?.volumeMetrics[data]['change'] >= 0 ? 'green' : 'red' }}>
                {textDataRecords?.volumeMetrics[data]['change'] >= 0 ? `+${textDataRecords?.volumeMetrics[data]['change']}% from baseline.` : `${textDataRecords?.volumeMetrics[data]['change']}% from baseline.`}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem' }}>
          {Object.keys(textDataRecords?.performanceMetrics).map((data) => (
            <Box key={data} sx={{ width: 'calc(50% - 1rem)', marginBottom: '1rem' }}>
              <Tooltip title={PERFORMANCE_OVERVIEW_TOOLTIPS[data]} placement='right' arrow>
                <Typography gutterBottom variant="caption" display="block" style={{ textTransform: 'capitalize' }}>
                  {data.replace(/_/g, ' ')}
                </Typography>
                <Typography variant="h4" style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                  {textDataRecords?.performanceMetrics[data]['data']}
                </Typography>
                <Typography variant="caption" display="block" style={{ color: textDataRecords?.performanceMetrics[data]['change'] >= 0 ? 'green' : 'red' }}>
                  {textDataRecords?.performanceMetrics[data]['change'] >= 0 ? `+${textDataRecords?.performanceMetrics[data]['change']}% from baseline.` : `${textDataRecords?.performanceMetrics[data]['change']}% from baseline.`}
                </Typography>
              </Tooltip>
            </Box>
          ))}
        </Box>
        <span>
          Cost per Ticket ($): {" "}
          <Input
            type="number"
            value={costPerTicket}
            onChange={(e) => {
              chatDataProp.handlecostPerTicket(e.target.value);
            }}
            style={{ width: "100px" }}
            startAdornment={
              <InputAdornment position="start">$</InputAdornment>
            }
          />
        </span>
      </InfoWrapper>
    </Info>
  )}
</Wrapper>
  );
};

// Exports:
export default TextData;