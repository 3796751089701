import moment from "moment";
import { REQUEST_CHART_EVENT_TYPE, REQUEST_CHART_SESSION_TYPE, REQUEST_GAP_TYPE, REQUEST_SESSION_TYPE } from "../../constants/requestType";

export function extractCoreText(verboseText) {
  // Find the position of the core topic by locating the single quotes
  const startPos = verboseText.indexOf('"') + 1; // Start after the first quote
  const endPos = verboseText.lastIndexOf('"'); // Find the last quote

  // Extract the topic based on the positions of the single quotes
  if (startPos > 0 && endPos > startPos) {
    return verboseText.substring(startPos, endPos);
  }

  // Return original label if the pattern doesn't match
  return verboseText;
}

// Function to calculate percentage safely
export const calculatePercentageSafely = (numerator, denominator) => {
  return Number(denominator) !== 0 ? (numerator / denominator) * 100 : 0;
};
// Function to calculate percentage change

export const calculatePercentageChange = (current, baseline) => {
  baseline = Number(baseline);
  return current !== 0 && !Number.isNaN(baseline) && baseline !== 0
    ? ((current - baseline) / Math.abs(baseline)) * 100
    : 0;
};

export const calculatePercentageValue = (percentage, number) => {
  return (percentage * number) / 100;
}

export const getRequestTypes = (startDate, endDate) => {
  const diff = moment(endDate).diff(startDate, 'days');
  let chatRequestType = REQUEST_CHART_SESSION_TYPE;
  let eventRequestType = REQUEST_CHART_EVENT_TYPE;

  if (diff <= 1) {
    chatRequestType = REQUEST_SESSION_TYPE;
    eventRequestType = REQUEST_GAP_TYPE;
  }
  return { chatRequestType, eventRequestType };
}

export const recordsFilteredByPersonas = (records, personas, persona, diff = 1) => {  
  if(!persona || persona?.includes("allPersona")) return  records;
  let filteredRecords = records;

  if (diff <= 1) {
    const personaFilters = {
      'Agent': record => record.user_email !== 'anonymous',
      'Customer': record => record.user_email === 'anonymous',
      'Default': {
        [persona.toString()]: record => record?.end_user?.personas?.some(per => persona?.includes(per)),
      }
    };
    filteredRecords = filteredRecords?.filter(personaFilters['Default'][persona]);
  }
  else {
    if (personas.includes(persona)) {
      filteredRecords = filteredRecords.filter(record => !!record?.user_personas?.hasOwnProperty(persona));
    }
  }
  return filteredRecords;
}

export const recordsFilteredByProfile = (records, profile) => {
  if (!profile || profile?.includes("All")) return records;
  return records?.filter(record => profile?.includes(record?.profile));
}

export const filterBySentiment = (records, sentiment) => {
  return sentiment?.length ? records.filter((rec) => sentiment.includes(rec?.analytics?.sentiment?.[0])) : records;
};

export const filterByOutcome = (records, outcome) => {
  return outcome?.length ? records.filter((rec) => outcome.includes(rec?.conversation_type)) : records;
};

export const applyTopicFilters = (records, filters) => {
  let filtered = records;
  filtered = filterBySentiment(filtered, filters.sentiment);
  filtered = filterByOutcome(filtered, filters.outcome);
  return filtered;
};

export const minSafe = (...args) => {
  return Math.min(...args.filter(Number.isFinite));
}

export const maxSafe = (...args) => {
  return Math.max(...args.filter(Number.isFinite));
}