import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

import { Bar, Chart } from 'react-chartjs-2'
import { applyTopicFilters, extractCoreText, recordsFilteredByPersonas, recordsFilteredByProfile } from "../utils";
import { CONVERSATION_TYPE } from "../../../constants/chat";
import { selectSessionItems } from "../../../redux/selectors/chatSelectors";
import chartConfig from '../../../config/infov3/charts.json'

const { backgroundColors, othersLabel } = chartConfig;
const { CONVERSATION_TYPE_AI } = CONVERSATION_TYPE;
const labelsNotSort = ["Total Sessions" ,"FCR %" ,"Cost Savings", "Avg CSAT Score", "Engagement Rate", othersLabel]

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
  )
  
const calculatePercentageSafely = (numerator, denominator) => {
  return denominator !== 0 ? (numerator / denominator) * 100 : 0;
};

function getCategoriesNames(chatRecords) {
  const categories ={}

  chatRecords?.forEach(record => {
    let intent;
    intent = record?.analytics?.intent?.[0];
    if (intent) {
      categories[intent] = categories[intent] || [];
    }
  });

  return categories
}

const options = {
  plugins: {
    title: {
      display: false,
      text: 'Session Chart',
    },
    tooltip: {
      filter: item => item.dataset.data[item.dataIndex] > 0,
      itemSort: function(a, b) {
        return b.raw - a.raw;
      }
    },
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 20,
        boxHeight: 18,
        padding: 15,
        sort: (a, b, chart) => {
          if(labelsNotSort.includes(a.text) || labelsNotSort.includes(b.text)){
            return Infinity;
          }
          const aTotal = chart.datasets[b.datasetIndex].data.reduce((pre, curr) => parseInt(pre) + parseInt(curr), 0);
          const bTotal = chart.datasets[a.datasetIndex].data.reduce((pre, curr) => parseInt(pre) + parseInt(curr), 0);
          return aTotal - bTotal;
        },
      },
    }
  },
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      }
    },
    y: {
      stacked: true,
      grid: {
        drawTicks: false,
      },
      border: {
        display: false,
      }
    },
  },
  layout: {
    padding: 25
  }
};

export default function UserChart({ startDate, endDate, costPerTicket, persona, topicFilters, profile }) {
  const chatRecords = useSelector(selectSessionItems, shallowEqual);


  const [labels, setVLabels] = useState([]);
  const [values, setVValues] = useState([]);
  const [sessions,setSessions] = useState([]);
  const personas = useSelector(state => state.content.personas);
  const [fcr,setFcr] = useState([]);
  const [costSavings,setCostSavings] = useState([]);
  const [avgCsatScore,setAvgCsatScore] = useState([]);
  const [engagementRate,setEngagementRate] = useState([]);
  const [categories,setCategories] = useState({});
  const handleLabels = useCallback((data) => setVLabels(data), []);
  const handleSessions = useCallback((data) => setSessions(data), [])
  const handleFcr = useCallback((data) => setFcr(data), [])
  const handleCostSavings = useCallback((data) => setCostSavings(data), [])
  const handleAvgCsatScore = useCallback((data) => setAvgCsatScore(data), [])
  const handleEngagementRate = useCallback((data) => setEngagementRate(data), [])
  const handleValues = useCallback((data) => setVValues(data), []);
  const handleCategories = useCallback((data) => setCategories(data), []);

  const [sortedValues, setSortedValues] = useState([]);
  const handleSortedValues = useCallback((data) => setSortedValues(data), []);
  const mappedData = Object.entries(categories).map(([label, values]) => 
    {
      const index = sortedValues.findIndex((el) => el[0] === label);
      const size = backgroundColors.length;
      return {
        label: extractCoreText(label),
        borderColor: "black",
        backgroundColor:  label === othersLabel ? backgroundColors[size - 1] : backgroundColors[index % size],
        borderWidth: 0,
        fill: false,
        data: values,
        stack: "Stack 0",
      }
    }
  );
  
  useEffect(()=>{
    const diff = moment(endDate).diff(startDate, 'days');
    let filteredRecords = recordsFilteredByPersonas(chatRecords, personas, persona);
    filteredRecords = recordsFilteredByProfile(filteredRecords, profile);

    const periodTypes = {
        '0': 'hour',
        '1': 'day',
        '31': 'month',
        '365': 'year',
    };
    filteredRecords = applyTopicFilters(filteredRecords, topicFilters);

    let periodType = periodTypes['0']; // Default aggregation function
    Object.keys(periodTypes).forEach(key => {
        if (diff > parseInt(key)) {
            periodType = periodTypes[key];
        }
    });
    let categories = getCategoriesNames(filteredRecords);
    aggregateDataByPeriod(startDate, endDate, periodType, filteredRecords, costPerTicket, handleLabels, handleValues, handleSessions, handleFcr, handleCostSavings, handleAvgCsatScore, handleEngagementRate, categories, handleCategories, handleSortedValues)
  }, [chatRecords, persona, topicFilters, profile]);
  
  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Total Sessions",
        borderColor: "black",
        borderWidth: 2,
        fill: false,
        data: sessions,
        pointRadius: 0,
        stack: "Stack 1",
        hidden: true,
      },
      {
        type: "line",
        label: "FCR %",
        borderColor: "blue",
        borderWidth: 2,
        fill: false,
        data: fcr,
        pointRadius: 0,
        stack: "Stack 2",
        hidden: true,
      },
      {
        type: "line",
        label: "Cost Savings",
        borderColor: "green",
        borderWidth: 2,
        fill: false,
        data: costSavings,
        pointRadius: 0,
        stack: "Stack 3",
        hidden: true,
      },
      {
        type: "line",
        label: "Avg CSAT Score",
        borderColor: "orange",
        borderWidth: 2,
        fill: false,
        data: avgCsatScore,
        pointRadius: 0,
        stack: "Stack 4",
        hidden: true,
      },
      {
        type: "line",
        label: "Engagement Rate",
        borderColor: "red",
        borderWidth: 2,
        fill: false,
        data: engagementRate,
        pointRadius: 0,
        stack: "Stack 5",
        hidden: true,
      },
    ].concat(mappedData),
  };
  return <Bar data={data} options={options}/>;
}


function aggregateDataByPeriod(startDate, endDate, periodType, chatRecords, costPerTicket, handleLabels, handleValues, handleSessions, handleFcr, handleCostSavings, handleAvgCsatScore, handleEngagementRate, categories, handleCategories, handleSortedValues) {
  let labels = [];
  let sessions = [];
  let fcr = [];
  let costSavings = [];
  let avgCsatScore = [];
  let engagementRate = [];
  let values = [];
  let currentDate = moment(startDate).startOf(periodType);
  let dateFormat = '';

  const periodFormats = {
      'year': 'YYYY',
      'month': 'MMM YYYY',
      'day': 'MMM D, YYYY',
      'hour': 'MMM D, YYYY HH:mm'
  };

  dateFormat = periodFormats[periodType] || 'MMM D, YYYY HH:mm';

  while (currentDate.isSameOrBefore(endDate)) {
    const start = moment(currentDate).startOf(periodType);
    const end = moment(currentDate).endOf(periodType);
    aggregateData(start, end, chatRecords, costPerTicket, labels, sessions, fcr, costSavings, avgCsatScore, engagementRate, values, categories, periodType);
    labels.push(start.format(dateFormat));
    currentDate.add(1, periodType);
  }

  let sortedValues = [];
  for(let cat in categories){
    let x = categories[cat].reduce((sum, val) => sum + val, 0)
    sortedValues.push([cat, x]);
  }

  sortedValues.sort((a, b) => a[1] - b[1]);
  handleSortedValues(sortedValues.reverse());
  let finalCategories = {};
  let othersArray = new Array(categories[Object.keys(categories)[0]]?.length).fill(0);
  sortedValues.forEach((val, idx) => {
    if (idx < 11) {
      finalCategories[val[0]] = categories[val[0]];
    } else {
      categories[val[0]].forEach((value, index) => {
        othersArray[index] += Number(value);
      });
    }
  });
  
  finalCategories[othersLabel] = othersArray;

  handleLabels(labels);
  handleSessions(sessions);
  handleFcr(fcr);
  handleCostSavings(costSavings);
  handleAvgCsatScore(avgCsatScore);
  handleEngagementRate(engagementRate);
  handleValues(values);
  handleCategories(finalCategories);
}

function aggregateData(start, end, chatRecords, costPerTicket, labels, sessions, fcr, costSavings, avgCsatScore, engagementRate, total, categories, periodType) {
  let ses = 0
  let fc = 0
  let totalUserQueries = 0;
  let totalRating = 0;
  let totalFeedback = 0;
  let temp = Object.keys(categories).reduce((obj, key) => ({...obj, [key]: 0}), {});
  for (let i = 0; i < chatRecords?.length || 0; i++) {
    const record = chatRecords[i];
    if (moment(record.created_date).isBetween(start, end, null, '[]')) {
      const isHourPeriod = periodType === 'hour';
      const intent = record?.analytics?.intent?.reduce((obj, key) => ({...obj, [key]: 1}), {});
      if (intent) {
        for(const key in intent){
          temp[key] = (temp[key] || 0) + intent[key];
        }
      }

      ses += 1;
      
      if(record?.conversation_type === CONVERSATION_TYPE_AI) fc++;
      if (record?.feedback && record?.feedback?.rating) {
        totalRating += (record?.feedback?.rating || 0)
        totalFeedback++
      }
      totalUserQueries += record?.analytics?.user_queries;
    }
  }

  Object.keys(temp).forEach(key => {
    if (categories[key]) categories[key].push(temp[key]);
  });
  
  const averageRating = totalFeedback !== 0 ? totalRating / totalFeedback : 0;
  let engagementRateValue =  ses !== 0 ? totalUserQueries / ses : 0;
  sessions.push(ses);
  fcr.push(calculatePercentageSafely(fc,ses).toFixed(2));
  costSavings.push(fc * costPerTicket);
  avgCsatScore.push(averageRating.toFixed(2));
  engagementRate.push(engagementRateValue.toFixed(2));
  total.push(ses);
}